import { EventEmitter } from '@angular/core';
import { ColumnState, IRowNode, RowSelectedEvent } from 'ag-grid-community';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';

import { SELECTION_ORDER_COLUMN_ID } from '../components/grid-cell-components/cell-selectors';
import { GridStatesQuery } from '../state/grid/grid-states.query';
import { GridSettingsService } from '../state/grid/grid-states.service';

/** Describes grid data. */
export class GridData {
  /** Observable of the current page. */
  currentPage$: Observable<number>;
  /** Emits when a grid refresh is requested. */
  gridRefresh = new EventEmitter<void>();
  /** Unique Id for this grid's data. */
  id: string;
  /** Observable of the selected row nodes. */
  selectedRowNodes$: Observable<IRowNode[]>;

  private currentPageSource = new BehaviorSubject<number>(0);
  private selectedRowNodesSource = new BehaviorSubject<IRowNode[]>([]);

  /**
   * Gets stored column states.
   *
   * @returns An array of column states.
   */
  get columnStates(): ColumnState[] {
    return this.gridStatesQuery.getColumnStates(this.id);
  }

  /**
   * Sets the column states.
   */
  set columnStates(newColumnStates: ColumnState[]) {
    this.gridStatesService.updateActiveColumnState(this.id, newColumnStates);
  }

  /**
   * Gets an observable array of column states.
   *
   *@returns An observable array of column states.
   */
  get columnStates$(): Observable<ColumnState[]> {
    return this.gridStatesQuery.getColumnStates$(this.id);
  }

  /**
   * Whether or there are column states stored.
   *
   * @returns A boolean indicating if there are column states stored.
   */
  get hasStoredColumnStates(): boolean {
    return !!(this.columnStates?.length > 0);
  }

  /**
   * Gets the currently selected row nodes.
   *
   * @returns An array of selected row nodes.
   */
  get selectedRowNodes(): IRowNode[] {
    return this.selectedRowNodesSource.getValue();
  }

  /**
   * Sets the selected row nodes.
   */
  set selectedRowNodes(rowNodes: IRowNode[]) {
    this.selectedRowNodesSource.next(rowNodes);
  }

  /**
   * Gets the current page being displayed.
   *
   * @returns A number.
   */
  get currentPage() {
    return this.currentPageSource.getValue();
  }

  /**
   * Sets the current page.
   *
   */
  set currentPage(page: number) {
    this.currentPageSource.next(page);
  }

  constructor(
    id: string,
    private logger: NGXLogger,
    private gridStatesQuery: GridStatesQuery,
    private gridStatesService: GridSettingsService
  ) {
    this.id = id;
    this.selectedRowNodes$ = this.selectedRowNodesSource.asObservable();
    this.currentPage$ = this.currentPageSource.asObservable();
  }

  /**
   * Handles row selection events.
   *
   * @param event Row selected event.
   * @returns All selected rows including the one that was just selected.
   */
  onRowSelected = (event: RowSelectedEvent): IRowNode[] => {
    this.logger.debug('On row selected event raised.', event);
    const selectedRows = this.selectedRowNodes;
    // Skip rows that are there but not displayed unless the row is being deselected in which case we always deselect it.
    if (!event.node.displayed && event.node.isSelected()) {
      return selectedRows;
    }
    const hasSelectionOrderColumn = !!event.api.getColumnDef(
      SELECTION_ORDER_COLUMN_ID
    );
    if (event.node.isSelected()) {
      selectedRows.push(event.node);
      if (hasSelectionOrderColumn) {
        event.node.setDataValue(SELECTION_ORDER_COLUMN_ID, selectedRows.length);
      }
    } else {
      const index = selectedRows.indexOf(event.node);
      if (index > -1) {
        selectedRows.splice(index, 1);
        if (hasSelectionOrderColumn) {
          event.node.setDataValue(SELECTION_ORDER_COLUMN_ID, '');
        }
      }
    }

    this.logger.debug('Selected rows have been ordered', selectedRows);
    this.selectedRowNodes = selectedRows;
    return selectedRows;
  };

  /** Emits that the grid should be refreshed. */
  refreshGrid(): void {
    this.logger.debug('Grid refresh requested.');
    this.gridRefresh.emit();
  }
}

/**
 * Describes archive grid data.
 *
 * @see GridData
 */
export class ArchiveGridData extends GridData {
  constructor(
    databaseId: number,
    archiveId: number,
    logger: NGXLogger,
    gridStatesQuery: GridStatesQuery,
    gridStateService: GridSettingsService
  ) {
    super(
      `${databaseId}_${archiveId}`,
      logger,
      gridStatesQuery,
      gridStateService
    );
  }
}

/**
 * Describes inbox grid state.
 *
 * @see GridData
 */
export class InboxGridData extends GridData {
  constructor(
    inboxId: number,
    logger: NGXLogger,
    gridStatesQuery: GridStatesQuery,
    gridStateService: GridSettingsService
  ) {
    super(`inbox_${inboxId}`, logger, gridStatesQuery, gridStateService);
  }
}
