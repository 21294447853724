import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { List, ListProvider } from 'models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { S9ApiList, createListFromApi } from '../models';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/** List service. */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiListService implements ListProvider {
  private basePath: string;

  constructor(
    private http: HttpClient,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => (this.basePath = apiUrl));
  }

  /** @inheritdoc */
  getDynamicList(
    databaseId: number,
    fieldId: number,
    filter1: string,
    filter2?: string
  ): Observable<List> {
    filter1 = encodeURIComponent(filter1);
    if (filter2) {
      filter2 = encodeURIComponent(filter2);
    }
    return this.http
      .get<S9ApiList>(
        `${this.basePath}/indexes/?database=${databaseId}&field=${fieldId}&Filter1=${filter1}&filter2=${filter2}`
      )
      .pipe(map((apiList) => createListFromApi(apiList)));
  }

  /** @inheritdoc */
  getList(databaseId: number, listId: number): Observable<List> {
    return this.http
      .get<S9ApiList>(
        `${this.basePath}/dbs/${databaseId}/lists/${listId}?index=`
      )
      .pipe(map((apiList) => createListFromApi(apiList)));
  }
}
